<div style="margin: 0 auto; max-width: 800px;">
    <div style="margin: 20px;">
        <mat-card data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
            <mat-card-title style="text-align: center; font-family: Oswald-Regular;">Skills</mat-card-title>
            <mat-card-content>
                <!-- <div class="progress-content-column"> -->
                    <div class="progress-container progress-primary">
                        <span class="progress-badge">HTML5</span>
                        <div class="progress">
                            <!--  data-aos="fade-right" data-aos-offset="10"
                            data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0"
                            aria-valuemax="100"  -->
                            <div class="progress-bar_1 progress-bar-primary_html" style="width: 80%;"></div>
                            <span class="progress-value">80%</span>
                        </div>
                    </div>
                    <div class="progress-container progress-primary">
                        <span class="progress-badge">CSS3</span>
                        <div class="progress">
                            <div class="progress-bar_2 progress-bar-primary" data-aos="fade-right" data-aos-offset="10"
                                data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0"
                                aria-valuemax="100" style="width: 75%;"></div><span class="progress-value">75%</span>
                        </div>
                    </div>
                <!-- </div> -->
                <div class="progress-content-column" style="margin-top: 12px;">
                    <div class="progress-container progress-primary">
                        <span class="progress-badge">JavaScript</span>
                        <div class="progress">
                            <div class="progress-bar_3 progress-bar-primary" data-aos="fade-right" data-aos-offset="10"
                                data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0"
                                aria-valuemax="100" style="width: 80%;"></div><span class="progress-value">80%</span>
                        </div>

                    </div>
                    <div class="progress-container progress-primary">
                        <span class="progress-badge">TypeScript</span>
                        <div class="progress">
                            <div class="progress-bar_4 progress-bar-primary" data-aos="fade-right" data-aos-offset="10"
                                data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0"
                                aria-valuemax="100" style="width: 80%;">
                            </div><span class="progress-value">80%</span>
                        </div>

                    </div>
                </div>
                <div class="progress-content-column" style="margin-top: 12px;">
                    <div class="progress-container progress-primary">
                        <span class="progress-badge">Angular</span>
                        <div class="progress">
                            <div class="progress-bar_5 progress-bar-primary" data-aos="fade-right" data-aos-offset="10"
                                data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0"
                                aria-valuemax="100" style="width: 75%;"></div><span class="progress-value">75%</span>
                        </div>
                    </div>
                </div>
                <div class="progress-content-column" style="margin-top: 12px;">
                    <div class="progress-container progress-primary">
                        <span class="progress-badge">React</span>
                        <div class="progress">
                            <div class="progress-bar_5 progress-bar-primary" data-aos="fade-right" data-aos-offset="10"
                                data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0"
                                aria-valuemax="100" style="width: 70%;"></div><span class="progress-value">70%</span>
                        </div>
                    </div>
                </div>

            </mat-card-content>
        </mat-card>
        <!-- <div id="skills-card" class="card">
            <div class="row">
                <div class="col-6 col-lg-6 py-2">
                    <div class="card-body">
                        <div class="progress-container progress-primary">
                            <span class="progress-badge">JavaScript</span>
                            <div class="progress">
                                <div class="progress-bar progress-bar-primary" data-aos="fade-right"
                                    data-aos-offset="10" data-aos-duration="2000" role="progressbar" aria-valuenow="60"
                                    aria-valuemin="0" aria-valuemax="100" style="width: 75%;"></div><span
                                    class="progress-value">75%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-lg-6 py-2">
                    <div class="card-body">
                        <div class="progress-container progress-primary">
                            <span class="progress-badge">TypeScript</span>
                            <div class="progress">
                                <div class="progress-bar progress-bar-primary" data-aos="fade-right"
                                    data-aos-offset="10" data-aos-duration="2000" role="progressbar" aria-valuenow="60"
                                    aria-valuemin="0" aria-valuemax="100" style="width: 50%;"></div><span
                                    class="progress-value">50%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6 col-lg-6 py-2">
                    <div class="card-body">

                    </div>
                </div>
                <div class="col-6 col-lg-6 py-2">
                    <div class="card-body">
                        <div class="progress-container progress-primary">
                            <span class="progress-badge">Python</span>
                            <div class="progress">
                                <div class="progress-bar progress-bar-primary" data-aos="fade-right"
                                    data-aos-offset="10" data-aos-duration="2000" role="progressbar" aria-valuenow="60"
                                    aria-valuemin="0" aria-valuemax="100" style="width: 40%;"></div><span
                                    class="progress-value">40%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6 col-lg-6 py-2">
                    <div class="card-body">
                        <div class="progress-container progress-primary">
                            <span class="progress-badge">SQL</span>
                            <div class="progress">
                                <div class="progress-bar progress-bar-primary" data-aos="fade-right"
                                    data-aos-offset="10" data-aos-duration="2000" role="progressbar" aria-valuenow="60"
                                    aria-valuemin="0" aria-valuemax="100" style="width: 70%;"></div><span
                                    class="progress-value">70%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-lg-6 py-2">
                    <div class="card-body">
                        <div class="progress-container progress-primary">
                            <span class="progress-badge">Sass</span>
                            <div class="progress">
                                <div class="progress-bar progress-bar-primary" data-aos="fade-right"
                                    data-aos-offset="10" data-aos-duration="2000" role="progressbar" aria-valuenow="60"
                                    aria-valuemin="0" aria-valuemax="100" style="width: 50%;"></div><span
                                    class="progress-value">50%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>