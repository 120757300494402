import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appParallaxFx]'
})
export class ParallaxFxDirective {
  @Input('ratio') parallaxRatio: number = 1;
  initialTop: number = 0;

  constructor(private elRef: ElementRef) {
    this.initialTop = this.elRef.nativeElement.getBoundingClientRect().top;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: any) {
    this.elRef.nativeElement.style.top =
      this.initialTop - window.scrollY * this.parallaxRatio + 'px';
  }
}
