import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  isOpen: boolean = false;
  @Output() openSideNav = new EventEmitter();
  @Output() closeSideNav = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }





}
