import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'eo-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.sass']
})
export class ProjectsComponent implements OnInit, AfterViewInit {
  @Input() rowHeight: string = "4:1";

  constructor(private breakpointObserver: BreakpointObserver, private cdRef: ChangeDetectorRef) { }
  ngAfterViewInit(): void {
    this.rowHeight = "100px";
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
  }

}
