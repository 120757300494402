import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'eo-about-section',
  templateUrl: './about-section.component.html',
  styleUrls: ['./about-section.component.sass'],
})
export class AboutSectionComponent implements OnInit {
  // @Input() aboutSectionTitle: string;
  // @Input() aboutSectionContent: string;

  


  constructor() { }

  ngOnInit(): void {
  }

}
