import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { delay } from 'rxjs/operators';
import { SpinnerOverlayService } from './modules/shared/services/spinner-overlay.service';
import * as AOS from 'aos';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  title = 'enoch-portfolio-work';

  openSideNav: boolean = false;
  @ViewChild('sidenav') public sideNav!: MatSidenav;
  @ViewChild(SidenavComponent) public sideBar?: SidenavComponent;
  pageLoading: boolean = false;


  constructor(private breakpointObserver: BreakpointObserver, private spinner: SpinnerOverlayService){}

  ngAfterViewInit(){
    this.breakpointObserver.observe(['(max-width: 500px)']).pipe(delay(1)).subscribe((res) => {
      if(res.matches){
        this.sideNav.mode = 'over';
        this.sideNav.close();
      } 
      // else {
      //   this.sideNav.mode = 'side';
      //   this.sideNav.open();
      // }
    });
  }

  ngOnInit(): void {
    this.spinner.show();

    AOS.init();

    setTimeout(() => {
      this.spinner.hide();
    }, 9000);
  }


  public toggleSideNav(): void{
    this.sideNav?.toggle();
  }

  public closeSideNav(){}

}
