import { Injectable } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerComponent } from 'src/app/components/spinner/spinner/spinner.component';


@Injectable({
  providedIn: 'root',
})
export class SpinnerOverlayService {
  private overlayRef?: OverlayRef;

  constructor(private overlay: Overlay) {}

  public show(message = ''){
    if(!this.overlayRef){
      this.overlayRef = this.overlay.create();
    }

    const spinnerOverlayPortal = new ComponentPortal(SpinnerComponent);
    const component = this.overlayRef.attach(spinnerOverlayPortal)
  }


  public hide(){
    if(!!this.overlayRef){
      this.overlayRef?.detach();
    }
  }
}
