<h1 mat-dialog-title>Details</h1>
<div mat-dialog-content>
  <form [formGroup]="formGroup">
    <mat-form-field appearance="fill" class="contact-form-field">
      <mat-label>First Name</mat-label>
      <input matInput type="text" placeholder="First Name" />
      <mat-icon matSuffix>badge</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="fill" class="contact-form-field">
      <mat-label>Surname</mat-label>
      <input matInput type="text" placeholder="Surname" />
      <mat-icon matSuffix>badge</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="fill" class="contact-form-field">
      <mat-label>Company</mat-label>
      <input matInput type="text" placeholder="Company" />
      <mat-icon matSuffix>work</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="fill" class="contact-form-field">
      <mat-label>Phone number</mat-label>
      <input matInput type="text" placeholder="Phone" />
      <mat-icon matSuffix>phone</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="fill" class="contact-form-field">
      <mat-label>Email</mat-label>
      <input matInput type="text" placeholder="Email" />
      <mat-icon matSuffix>email</mat-icon>
    </mat-form-field>
  </form>
</div>
<mat-dialog-actions style=" display: block;">
  <div style="text-align: end;">
    <button mat-flat-button color="primary" style="background: #101a45; color: ghostwhite;">Save</button>
  </div>
</mat-dialog-actions>