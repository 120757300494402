
<section>
    <mat-drawer-container>
        <mat-drawer-content>
            <div class="main-view-content">
                <div class="profile-header">
                    <div class="profile-bg-animate">
                        <div class="profile-bg-animate-layer">
                        </div>
                        <div style="position: relative; bottom: 30px;">
                            <div class="eo-profile-image">
                                <a href="#"><img src="assets/images/newpic2.png" alt="Image" />
                                </a>
                            </div>
                            <div class="main-view-title">
                                <div style="margin-top: 10px;">
                                    <h1 class="profile-name-text">Enoch Offiah</h1>
                                    <p class="profile-name-text">Web Developer / Front-End Developer</p>
                                </div>

                            </div>
                            <div class="profile-button-container">
                                <div class="action-button-container">
                                    <button class="profile-button" mat-stroked-button color="primary" (click)="openContactForm();">
                                        <span class="material-icons" style="margin-right: 5px;
                                        position: relative;">
                                            <mat-icon style="font-size: 18px;">thumb_up</mat-icon>
                                        </span>Hire Me</button>
                                    <button style="margin-left: 5px;" class="profile-button" mat-stroked-button
                                        color="primary" (click)="downloadCV();">
                                        <span class="material-icons" style="margin-right: 5px;
                                        position: relative;">
                                            <mat-icon style="font-size: 18px;">download_for_offline</mat-icon>
                                        </span>Download CV</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- about section -->
            <div>
                <eo-about-section></eo-about-section>
            </div>

            <div>
                <eo-projects></eo-projects>
            </div>

            <div>
                <eo-skills-exp-section></eo-skills-exp-section>
            </div>

                


        </mat-drawer-content>
    </mat-drawer-container>
</section>


<!-- portfolio section -->
<!--profile page content component -->