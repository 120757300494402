<!-- todo modify below div to apply flexLayout -->
<!-- fxFlex.lg="33%" fxFlex.lt-lg="50%" fxFlex.xs="100%" -->
<div style="margin: 0 auto; max-width: 750px;">
  <mat-card class="background-info-card" style="margin: 10px 20px;">
    <mat-card-header>
      <mat-card-title style="text-align: center; font-family: Oswald-Regular;">Background</mat-card-title>
      <mat-card-subtitle></mat-card-subtitle>
    </mat-card-header>
    <!-- <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu"> -->
    <mat-card-content>
      <div style="text-align: start;
      font-size: large;
      display: flex;
      position: relative;
      flex-direction: column;
            ">
        <p>Inventive Web Developer creates novel site designs and innovative user interfaces using knowledge in Angular
          10+, C# API development, .NET framework.</p>


        <p>
          Strongly committed to continuous professional development and hunger to learn more about the
          cutting edge technologies within front-end and web
          development. I am skilled in JavaScript, TypeScript, C#, HTML, CSS3, Sass, SQL.
        </p>


        <p>I also do some freelance Mobile Phone & Tablet repairs in my spare time.</p>
        <br />
        <div style="display: flex;">
          <p style="font-weight: 600;">Age:</p>
          <span style="margin-left: 5px;">
            <p>28</p>
          </span>
        </div>

        <div style="display: flex; flex-direction: flex-end;">
          <p style="font-weight: 600;">Email:</p>
          <span style="margin-left: 5px;">
            <p>enochoffiah@yahoo.co.uk</p>
          </span>
        </div>

        <div style="display: flex; flex-direction: flex-end;">
          <p style="font-weight: 600;">Phone:</p>
          <span style="margin-left: 5px;">
            <p>(+44)077647000633</p>
          </span>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
    </mat-card-actions>
  </mat-card>
</div>