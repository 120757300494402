<section>
    <div style="display: flex; margin-top: 125px;">
        <h2 style="
        margin: 10px auto;
        font-size: x-large;
        font-family: Oswald-Regular;">Projects</h2>
    </div>
    <div style="border-top: #26376e 5px solid">
        <app-gallery></app-gallery>
    </div>
</section>